import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import EditTrainerModal from '../components/EditTrainerModal';
import './AdminPanel.css';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PAGE_SIZE = 15; // Number of trainers per page

const AdminDashboard = () => {
  const [trainers, setTrainers] = useState([]);
  const [error, setError] = useState(null);
  const [editTrainer, setEditTrainer] = useState(null);
  const [filteredTrainers, setFilteredTrainers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedSex, setSelectedSex] = useState('');
  const [selectedOnline, setSelectedOnline] = useState('');
  const [filter, setFilter] = useState({
    searchName: '',
    location: [],
    experience: [],
    sex: '',
    online: ''
  });

  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        const response = await axios.get('https://server.findmytrainer.co.zw/api/trainers');
        setTrainers(response.data);
        setError(null);
      } catch (error) {
        setError('Error fetching trainers. Please try again.');
        console.error('Error fetching trainers:', error);
      }
    };
    fetchTrainers();
  }, []);

  useEffect(() => {
    // Filtering logic based on selected filters
    const filtered = trainers.filter(trainer => {
      return (
        (trainer.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
         trainer.last_name.toLowerCase().includes(searchName.toLowerCase())) &&
        (selectedLocation.length === 0 || selectedLocation.includes(trainer.location)) &&
        (selectedExperience.length === 0 || selectedExperience.includes(trainer.experience_years)) &&
        (selectedSex === '' || trainer.sex === selectedSex) &&
        (selectedOnline === '' || trainer.online_coaching === selectedOnline)
      );
    });

    setFilteredTrainers(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [searchName, selectedLocation, selectedExperience, selectedSex, selectedOnline, trainers]);

  const paginatedTrainers = filteredTrainers.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
  const totalPages = Math.ceil(filteredTrainers.length / PAGE_SIZE);

  const handleApprove = async (id) => {
    try {
      await axios.patch(`http://localhost:5000/api/trainers/approve/${id}`);
      setTrainers(trainers.map(trainer => trainer.id === id ? { ...trainer, approved: 1 } : trainer));
    } catch (error) {
      setError('Error approving trainer. Please try again.');
      console.error('Error approving trainer:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/delete/${id}`);
      setTrainers(prevTrainers => prevTrainers.filter(trainer => trainer.id !== id));
    } catch (error) {
      setError('Error deleting trainer. Please try again.');
      console.error('Error deleting trainer:', error);
    }
  };

  const handleEdit = (trainer) => {
    setEditTrainer(trainer);
  };


  return (
    
    <div className='admin-panel-main'>
      <Header/>
      <div className='admin_panel_header'>
       <h1>Admin Dashboard</h1>
      </div>
      <div className='admin-panel'>
      <div className="filter-sidebar">
        <h2 className="filter-heading">Filters</h2>
        <div className="filter-ui-section">
          <div className="filter-heading-block">
            <div className="search-results-count">
              Showing <span className="results-count">{filteredTrainers.length}</span> results of <span className="total-items-count">{trainers.length}</span> items.
            </div>
            <a href="#" className="reset-all" onClick={() => {
              setSearchName('');
              setSelectedLocation([]);
              setSelectedExperience([]);
              setSelectedSex('');
              setSelectedOnline('');
            }}>Reset all</a>
          </div>
        </div>

        <div className="filter-ui-section">
          <h3 className="filter-heading_1">Search trainer name:</h3>
          <input
            className="search-name-field"
            maxLength="256"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Enter name"
            type="text"
            required
          />
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Select location(s):</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedLocation([])}>Clear</a>
          </div>
          <div className="filter-list">
            {['Online', 'Harare', 'Bulawayo', 'Mutare', 'Gweru', 'Victoria Falls'].map(location => (
              <div key={location} className="filter-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedLocation.includes(location)}
                    onChange={() => {
                      setSelectedLocation(prevState =>
                        prevState.includes(location)
                          ? prevState.filter(loc => loc !== location)
                          : [...prevState, location]
                      );
                    }}
                  />
                  {location}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Experience:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedExperience([])}>Clear</a>
          </div>  
          <div className="filter-list">
            {['Less than 1 year', 'Between 1-3 years', 'Between 3-5 years'].map(exp => (
              <div key={exp} className="filter-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedExperience.includes(exp)}
                    onChange={() => {
                      setSelectedExperience(prevState =>
                        prevState.includes(exp)
                          ? prevState.filter(expItem => expItem !== exp)
                          : [...prevState, exp]
                      );
                    }}
                  />
                  {exp}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Trainer sex:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedSex('')}>Clear</a>
          </div>
          <div className="filter-radio">
            <label>
              <input
                type="radio"
                name="sex"
                value=""
                checked={selectedSex === ''}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Any
            </label>
            <label>
              <input
                type="radio"
                name="sex"
                value="Male"
                checked={selectedSex === 'Male'}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Male
            </label>
            <label>
              <input
                type="radio"
                name="sex"
                value="Female"
                checked={selectedSex === 'Female'}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Female
            </label>
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Offer online coaching:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedOnline('')}>Clear</a>
          </div>
          <div className="filter-radio">
            <label>
              <input
                type="radio"
                name="online"
                value=""
                checked={selectedOnline === ''}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              Any
            </label>
            <label>
              <input
                type="radio"
                name="online"
                value="Yes"
                checked={selectedOnline === 'Yes'}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="online"
                value="No"
                checked={selectedOnline === 'No'}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              No
            </label>
          </div>
        </div>
      </div>
      
      <div className="container20 mt-55">
        
        {error && <div className="alert alert-danger">{error}</div>}

        <TrainerTable
          trainers={filteredTrainers.filter(trainer => !trainer.approved)}
          title="Not Approved Trainers"
          handleApprove={handleApprove}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />

        <TrainerTable
          trainers={filteredTrainers.filter(trainer => trainer.approved)}
          title="Approved Trainers"
          handleApprove={handleApprove}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isApprovedSection={true}
        />

        {editTrainer && (
          <EditTrainerModal
            trainer={editTrainer}
            onClose={() => setEditTrainer(null)}
            onSave={updatedTrainer => {
              setTrainers(trainers.map(trainer => trainer.id === updatedTrainer.id ? updatedTrainer : trainer));
              setEditTrainer(null);
            }}
          />
        )}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

const FilterSidebar = ({ filter, handleFilterChange, handleResetFilters, totalTrainers, filteredTrainersCount }) => (
  <div className="filter-sidebar">
    <h2 className="filter-heading">Filters</h2>
    <div className="filter-ui-section">
      <div className="filter-heading-block">
        <div className="search-results-count">
          Showing <span className="results-count">{filteredTrainersCount}</span> results of <span className="total-items-count">{totalTrainers}</span> items.
        </div>
        <a href="/#" className="reset-all" onClick={handleResetFilters}>Reset all</a>
      </div>
    </div>

    {/* Additional filter sections go here, e.g., name, location, experience, etc. */}
    {/* The structure can be similar to the original implementation */}

  </div>
  
);

const TrainerTable = ({ trainers, title, handleApprove, handleEdit, handleDelete, isApprovedSection }) => (
  <div>
    <h2 className='approved_or_not'>{title}</h2>
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Approved</th>
          <th>Approve</th>
          <th>Read</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {trainers.map(trainer => (
          <tr key={trainer.id}>
            <td>{trainer.id}</td>
            <td>{trainer.first_name}</td>
            <td>{trainer.last_name}</td>
            <td>{trainer.email}</td>
            <td>{trainer.approved ? 'Yes' : 'No'}</td>
            <td>
            {!trainer["approved"] && (
              <button className="btn btn-success" onClick={() => handleApprove(trainer.id)}>Approve</button>
            )}
            </td>
            <td>
              <Link className='btn mx-2 btn-success' to={`/read/${trainer.id}`}>Read</Link>
            </td>
            <td>
              <Link className='btn mx-2 btn-success' to={`/edit/${trainer.id}`}>Edit</Link>
            </td>
            <td>
            <button onClick={()=>handleDelete(trainer.id)} className='btn mx-2 btn-danger'>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default AdminDashboard;
