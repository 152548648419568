import React, { useState, useEffect } from 'react';
import './About.css'
import Header from '../components/Header';
import Footer from '../components/Footer';


const About = () => {
return(
    <div className="about">
        <Header/>

        <div class="et_builder_inner_content et_pb_gutters3">
		    <div class="et_pb_section4 et_pb_section_04 et_pb_with_background et_section_regular">
				<div class="et_pb_row et_pb_row_0">
				    <div class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
				        <div class="et_pb_module et_pb_heading et_pb_heading_0 et_pb_bg_layout_">
				            <div class="et_pb_heading_container"><h1 class="et_pb_module_heading4">About Find My Trainer</h1></div>
			            </div>
                        <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_center et_pb_bg_layout_light">
                            <div class="et_pb_text_inner">
                                <div class="flex max-w-full flex-col flex-grow">
                                    <div data-message-author-role="assistant" data-message-id="0baa751d-7f61-4cc0-858c-5da45587f395" dir="auto" class="min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                                        <div class="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">
                                            <div class="markdown prose w-full break-words dark:prose-invert dark">
                                                <p class='header4_text'>“Connecting personal trainers and clients to build a community that promotes better health and provides an environment for people to prosper.”</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
			            </div>
                        <div class="et_pb_button_module_wrapper et_pb_button_0_wrapper et_pb_button_alignment_center et_pb_module ">
				            <a class="et_pb_button4 et_pb_button_04 et_pb_bg_layout_light" href="/browse">Browse Personal Trainers</a>
			            </div>
			        </div>
				</div>
			</div>
            <div class="et_pb_section4 et_pb_section_1 et_pb_with_background et_section_regular">
				<div class="et_pb_row4 et_pb_row_1 et_hover_enabled">
				    <div class="et_pb_column et_pb_column_1_2 et_pb_column_14  et_pb_css_mix_blend_mode_passthrough">
				        <div class="et_pb_module et_pb_text et_pb_text_1 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
			                <div class="et_pb_text_inner"><h3>About</h3></div>
			            </div>
                        <div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><h2>What is the aim of Find My Trainer?</h2></div>
			    </div>
                <div class="et_pb_module et_pb_image et_pb_image_0 et_hover_enabled">
				    <span class="et_pb_image_wrap has-box-shadow-overlay"><div class="box-shadow-overlay"></div><img fetchpriority="high" decoding="async" width="1000" height="973" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-07.jpg" alt="" title="personal-trainer-07" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-07.jpg 1000w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-07-980x954.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-07-480x467.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1000px, 100vw" class="wp-image-23"/></span>
			    </div>
			</div>
            <div class="et_pb_column et_pb_column_1_2 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
				<div class="et_pb_module et_pb_text et_pb_text_3 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
				    <div class="et_pb_text_inner"><p class='all_text'>Find My Trainer was designed to make finding the right personal trainer easy and efficient, connecting you with top trainers from across the country. Our platform allows you to compare various trainers, ensuring you find the one that best fits your needs and fitness goals.</p>
                        <p class='all_text'>Each trainer on our platform has a detailed profile page, highlighting their skills, expertise, and unique approach to training. By exploring these profiles, you gain insight into what each trainer offers, making it easier to select the one that aligns perfectly with your expectations. This way, you can feel confident in your choice and start your fitness journey with the right guidance from day one.</p></div>
			        </div>
                    <div class="et_pb_module et_pb_text et_pb_text_44  et_pb_text_align_left et_pb_bg_layout_light">
				        <div class="et_pb_text_inner"><h4>Our Mission</h4>
                            <p class='all_text'>Our mission is to empower individuals from all walks of life to achieve greater wellness and productivity by implementing cutting-edge health and wellness strategies. We strive to transform lives into happier, healthier, and more vibrant experiences, fostering a culture of reduced stress and increased morale.</p></div>
			            </div>
			        </div>
			    </div>
                <div class="et_pb_row et_pb_row_2">
				    <div class="et_pb_column et_pb_column_4_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
				        <div class="et_pb_module et_pb_text et_pb_text_5 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
				            <div class="et_pb_text_inner"><h3>Choose Us</h3></div>
			            </div>
        
                    <div class="et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_left et_pb_bg_layout_light">
				<div class="et_pb_text_inner"><h2>Why choose Find My Trainer?</h2></div>
			</div>
		</div>
				
				
				
				
			</div><div class="et_pb_row4 et_pb_row_34">
				<div class="et_pb_column4 et_pb_column_1_34 et_pb_column_44  et_pb_css_mix_blend_mode_passthrough">
				    <div class="et_pb_module et_pb_blurb et_pb_blurb_0  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				        <div class="et_pb_blurb_content">
					        <div class="et_pb_blurb_container">
						    <h4 class="et_pb_module_header"><span>We remove the guesswork.</span></h4>
						    <div class="et_pb_blurb_description">
                                <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                                    <div class="walkthrough-blurb4"><span class='all_text'>Each profile provides all the key information to help you choose the right personal trainer with confidence.</span></div>
                                </div>
                            </div>
					    </div>
				    </div>
			    </div>
			</div>
            <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_54  et_pb_css_mix_blend_mode_passthrough">
				<div class="et_pb_module et_pb_blurb et_pb_blurb_1  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				    <div class="et_pb_blurb_content">
					    <div class="et_pb_blurb_container">
						    <h4 class="et_pb_module_header"><span>We only work with the best.</span></h4>
						    <div class="et_pb_blurb_description">
                                <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                                    <div class="walkthrough-blurb4"><span class='all_text'>All personal trainers are vetted to ensure they’re equipped to help you achieve your goals.</span></div>
                                </div>
                            </div>
					    </div>
				    </div>
			    </div>
			</div>
            <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_64  et_pb_css_mix_blend_mode_passthrough et-last-child">
				<div class="et_pb_module et_pb_blurb et_pb_blurb_2  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				    <div class="et_pb_blurb_content">
					    <div class="et_pb_blurb_container">
						    <h4 class="et_pb_module_header"><span>We work with you, for you.</span></h4>
						    <div class="et_pb_blurb_description">
                                <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                                    <div class="walkthrough-blurb4"><span class='all_text'>At FirstRep, we strive to be our best and therefore welcome and encourage your feedback at all times</span></div>
                                </div>
                            </div>
					    </div>
				    </div>
			    </div>
			</div>
		</div>
        
            <div class="et_pb_row4 et_pb_row_44">
				<div class="et_pb_column4 et_pb_column_2_34 et_pb_column_74  et_pb_css_mix_blend_mode_passthrough">
				    <div class="et_pb_module et_pb_text et_pb_text_74 et_hover_enabled et_pb_text_align_right et_pb_bg_layout_dark et_had_animation" >
				
				
				
				
				<div class="et_pb_text_inner"><h2>Train</h2></div>
			</div>
			</div>
            <div class="et_pb_column et_pb_column_1_34 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				
				
				<div class="et_pb_button_module_wrapper et_pb_button_1_wrapper4 et_pb_button_alignment_left et_pb_module ">
				<a class="et_pb_button et_pb_button_14 et_pb_bg_layout_light" href="/browse">Browse Personal Trainers</a>
			</div>
            <div class="et_pb_button_module_wrapper et_pb_button_1_wrapper44 et_pb_button_alignment_left et_pb_module ">
            <a class="et_pb_button et_pb_button_24 et_pb_bg_layout_light" href="/trainer-register">Register As A Personal Trainer</a>
			</div>

			</div>
				
				
				
				
			</div>
				
				
			</div>
            <div class="et_pb_section4 et_pb_section_2 et_section_regular">
				<div class="et_pb_row3 et_pb_row_5 et_pb_gutters2">
				    <div class="et_pb_column4 et_pb_column_4_44 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">
				        <div class="et_pb_module et_pb_text et_pb_text_8 et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
				            <div class="et_pb_text_inner"><h3>About Us</h3></div>
			            </div>
                        <div class="et_pb_module et_pb_text et_pb_text_9  et_pb_text_align_left et_pb_bg_layout_light">
				            <div class="et_pb_text_inner"><h2>How Find My Trainer Works</h2></div>
			            </div>
			        </div>
			    </div>
                <div class="et_pb_row4 et_pb_row_6 et_pb_equal_columns et_pb_gutters2">
				    <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_104  et_pb_css_mix_blend_mode_passthrough">
				        <div class="et_pb_module et_pb_blurb et_pb_blurb_3  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				            <div class="et_pb_blurb_content">
					            <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/search.png" alt="Find A Trainer" class="home_blurb_image"/></span></div>
					                <div class="et_pb_blurb_container">
						                <h4 class="et_pb_module_header"><span>Find a personal trainer</span></h4>
						                    <div class="et_pb_blurb_description">
                                                <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                                                    <div class="walkthrough-blurb">Compare the best personal trainers until you find one that’s right for you!</div>
                                                </div>
                                            </div>
					                    </div>
				                    </div>
			                    </div>
			                </div>
                            <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_114  et_pb_css_mix_blend_mode_passthrough">
				                <div class="et_pb_module et_pb_blurb et_pb_blurb_4  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				                    <div class="et_pb_blurb_content">
					                    <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/calendar.png" alt="Book A Session" class="home_blurb_image"/></span></div>
					                        <div class="et_pb_blurb_container">
						                        <h4 class="et_pb_module_header"><span>Book a session</span></h4>
						                        <div class="et_pb_blurb_description">
                                                    <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                                                        <div class="walkthrough-blurb">
                                                            <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b08-b0669af8" class="walkthrough-wrapper">
                                                                <div class="walkthrough-blurb">Complete our enquiry form to arrange a session with your personal trainer!</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
					                        </div>
				                        </div>
			                        </div>
			                    </div>
                                <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_124  et_pb_css_mix_blend_mode_passthrough et-last-child">
				                    <div class="et_pb_module et_pb_blurb et_pb_blurb_5  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				                        <div class="et_pb_blurb_content">
					                        <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/check1.png" alt="Progress" class="home_blurb_image"/></span></div>
					                            <div class="et_pb_blurb_container">
						                            <h4 class="et_pb_module_header"><span>Progress</span></h4>
						                            <div class="et_pb_blurb_description">
                                                                            <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669af8-b0669af8" class="walkthrough-wrapper">
                                                                                <div class="walkthrough-blurb">
                                                                                    <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b10-b0669af8" class="walkthrough-wrapper">
                                                                                        <div class="walkthrough-blurb">Work with your trainer and start smashing your fitness goals!</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                    
                                                                
                                                            
                                                        
                                                    </div>
					                            </div>
				                            </div>
			                            </div>
			                        </div>
			                    </div>
			</div>		
        </div>

        <Footer/>

    </div>
)
};

export default About;