import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure axios is installed
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './BrowseTrainers.css'; // Ensure you have this CSS file
import Header from '../components/Header';
import Footer from '../components/Footer';

const PAGE_SIZE = 15; // Number of trainers per page

const BrowseTrainers = () => {
  const [trainers, setTrainers] = useState([]);
  const [filteredTrainers, setFilteredTrainers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedSex, setSelectedSex] = useState('');
  const [selectedOnline, setSelectedOnline] = useState('');

  useEffect(() => {
    // Fetch trainers from the backend
    const fetchTrainers = async () => {
      try {
        const response = await axios.get('https://server.findmytrainer.co.zw/api/trainers'); // Adjust the URL if necessary
        const approvedTrainers = response.data.filter(trainer => trainer.approved === 1);
        setTrainers(approvedTrainers);
      } catch (error) {
        console.error('Error fetching trainers:', error);
      }
    };

    fetchTrainers();
  }, []);

  useEffect(() => {
    // Filtering logic based on selected filters
    const filtered = trainers.filter(trainer => {
      return (
        (trainer.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
         trainer.last_name.toLowerCase().includes(searchName.toLowerCase())) &&
        (selectedLocation.length === 0 || selectedLocation.includes(trainer.location)) &&
        (selectedExperience.length === 0 || selectedExperience.includes(trainer.experience_years)) &&
        (selectedSex === '' || trainer.sex === selectedSex) &&
        (selectedOnline === '' || trainer.online_coaching === selectedOnline)
      );
    });

    setFilteredTrainers(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [searchName, selectedLocation, selectedExperience, selectedSex, selectedOnline, trainers]);

  const paginatedTrainers = filteredTrainers.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
  const totalPages = Math.ceil(filteredTrainers.length / PAGE_SIZE);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className='main'>
      <Header/>

      <div class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">
				<div class="et_pb_row et_pb_row_0">
				  <div class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
            <div class="et_pb_module et_pb_heading et_pb_heading_0 et_pb_bg_layout_">
              <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Find the RIGHT personal trainer</h1>
              </div>
			      </div>
            <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_center et_pb_bg_layout_light">
              <div class="et_pb_text_inner">
                <div class="page-title-section1">
                  <div class="page-header w-container">
                    <p class="page-subtitle1">Compare the best personal trainers in Zimbabwe.</p>
                  </div>
                </div>
              </div>
			      </div>
			    </div>
				</div>
			</div>
  
    <div className="container">
      <div className="filter-sidebar">
        <h2 className="filter-heading">Filters</h2>
        <div className="filter-ui-section">
          <div className="filter-heading-block">
            <div className="search-results-count">
              Showing <span className="results-count">{filteredTrainers.length}</span> results of <span className="total-items-count">{trainers.length}</span> items.
            </div>
            <a href="#" className="reset-all" onClick={() => {
              setSearchName('');
              setSelectedLocation([]);
              setSelectedExperience([]);
              setSelectedSex('');
              setSelectedOnline('');
            }}>Reset all</a>
          </div>
        </div>

        <div className="filter-ui-section">
          <h3 className="filter-heading_1">Search trainer name:</h3>
          <input
            className="search-name-field"
            maxLength="256"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Enter name"
            type="text"
            required
          />
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Select location(s):</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedLocation([])}>Clear</a>
          </div>
          <div className="filter-list">
            {['Online', 'Harare', 'Bulawayo', 'Mutare', 'Gweru', 'Victoria Falls'].map(location => (
              <div key={location} className="filter-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedLocation.includes(location)}
                    onChange={() => {
                      setSelectedLocation(prevState =>
                        prevState.includes(location)
                          ? prevState.filter(loc => loc !== location)
                          : [...prevState, location]
                      );
                    }}
                  />
                  {location}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Experience:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedExperience([])}>Clear</a>
          </div>  
          <div className="filter-list">
            {['Less than 1 year', 'Between 1-3 years', 'Between 3-5 years'].map(exp => (
              <div key={exp} className="filter-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedExperience.includes(exp)}
                    onChange={() => {
                      setSelectedExperience(prevState =>
                        prevState.includes(exp)
                          ? prevState.filter(expItem => expItem !== exp)
                          : [...prevState, exp]
                      );
                    }}
                  />
                  {exp}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Trainer sex:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedSex('')}>Clear</a>
          </div>
          <div className="filter-radio">
            <label>
              <input
                type="radio"
                name="sex"
                value=""
                checked={selectedSex === ''}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Any
            </label>
            <label>
              <input
                type="radio"
                name="sex"
                value="Male"
                checked={selectedSex === 'Male'}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Male
            </label>
            <label>
              <input
                type="radio"
                name="sex"
                value="Female"
                checked={selectedSex === 'Female'}
                onChange={(e) => setSelectedSex(e.target.value)}
              />
              Female
            </label>
          </div>
        </div>

        <div className="filter-ui-section">
          <div class="filter-heading-block">
            <h3 className="filter-heading_1">Offer online coaching:</h3>
            <a href="#" className="clear-filter" onClick={() => setSelectedOnline('')}>Clear</a>
          </div>
          <div className="filter-radio">
            <label>
              <input
                type="radio"
                name="online"
                value=""
                checked={selectedOnline === ''}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              Any
            </label>
            <label>
              <input
                type="radio"
                name="online"
                value="Yes"
                checked={selectedOnline === 'Yes'}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="online"
                value="No"
                checked={selectedOnline === 'No'}
                onChange={(e) => setSelectedOnline(e.target.value)}
              />
              No
            </label>
          </div>
        </div>
      </div>
      
      <div className="trainer-content">
      <div className="trainer-grid">
        {paginatedTrainers.map(trainer => (
          <div key={trainer.id} className="trainer-card">
            <div className="trainer-image">
              <img src={trainer.profile_photo} alt={trainer.first_name + ' ' + trainer.last_name} />
            </div>
            <div className="trainer-details">
              <div className="trainer-name">{trainer.first_name} {trainer.last_name}</div>
              <div className="trainer-info">
                <div className="trainer-info-item">Location: {trainer.location}</div>
                <div className="trainer-info-item">Rate: ${trainer.charge_per_session} per session</div>
              </div>
              <Link to={`/trainers/${trainer.id}`} className="view-profile-btn">View Profile</Link>
            </div>
          </div>
        ))}
      </div>
      
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Prev
        </button>
        <span className="pagination-info">Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
      </div>

    </div>

    <Footer/>
        
    </div>
  );
};

export default BrowseTrainers;
