import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Updated import
import './Modal.css'

const TrainerSignUpModal = ({ show, handleClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate(); // Updated hook

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const trainerData = { firstName, lastName, email, password };
    try {
      const response = await axios.post("https://server.findmytrainer.co.zw/api/trainers/register", trainerData);
      setStatusMessage("Registration successful! Your account is pending approval.");
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      // Redirect to the post-registration page with the trainer ID
      navigate(`/post-registration/${response.data.trainerId}`); // Updated navigation method
    } catch (error) {
      console.error("Error creating trainer account:", error);
      setStatusMessage("There was an error with your registration.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create an account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@domain.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Sign up
          </Button>
          {statusMessage && <div className="mt-3">{statusMessage}</div>}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TrainerSignUpModal;
